import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { BreadcrumbService, CategoryService, QuestionAnswerService, QuizService, AlertService, UserService} from '../../services';
declare var $: any;
@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {
  notification = this.alertService.getMessageFormat();

  addQuizForm: FormGroup;
  csvForm:FormGroup;
  breadcrumb_data: any;
  loading = false;
  submitted = false;
  csvSubmitted =false;
  csvLoading =false;
  
  form = {
    label: 'Create',
    id: null
  };
  message = {
    status: '',
    msg: ''
  };
  quizMessage = {
    status: '',
    msg: ''
  };
  pageSize: number = 10; /* number of items per page */
  currentPage = 1; /* here the currentPage of pagination is set as 1. */
  totalQuizzes: number = 0;// it is the paginated list of the billingLists

  QACurrentPage: number = 1;
  totalQuestionAns: number = 0;
  catId: any;
  subCatId: any;
  quizId: string;

  /* on page changed, change the list of array per page */
  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getQuizList();
  }

  QApageChanged(event: any): void {
    this.QACurrentPage = event.page;
    this.getQA(this.quizDetail);
  }
  constructor(
    private breadcrumbService: BreadcrumbService,
    private categoryService: CategoryService,
    private quizService: QuizService,
    private formBuilder: FormBuilder,
    private questionAnswerService: QuestionAnswerService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private userService: UserService
  ) {
    this.breadcrumb_data = [{name: "Dashboard", link: '/dashboard'}, {name:"Category List", link: '/dashboard/category'}];
  }

  ngOnInit() {
    this.quizId = this.route.snapshot.paramMap.get('quizId');
    this.catId = this.route.snapshot.paramMap.get('catId');
    this.subCatId = this.route.snapshot.paramMap.get('subCatId');
    this.getSubcategoryDetail();
    this.getQuizList();

    this.initForm();
    this.csvFormGroup();
  }
  csvFormGroup(){
    this.csvForm = this.formBuilder.group({
      file :[null],
      quizes:[this.quizes]
    })
  }

  initForm() {
    this.addQuizForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      max_questions: ['', Validators.required],
      pass_mark: ['', Validators.required],
      success_text: ['', Validators.required],
      fail_text: ['', Validators.required],
      duration: [null, [Validators.pattern(/^([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)$/g)]],
      points: ['', Validators.required],
      random_order: [false],
      single_attempt: [false],
      answers_at_end: [null], // validators only work for null and empty string value
      draft: [true]
    });
  }

  /* get the errors and values */
  get quizControl() {
    return this.addQuizForm.controls;
  }

  /* setValidation */
  setValidation() {
    const singleAttemptControl = this.quizControl.single_attempt,
      single_attempt = singleAttemptControl.value,
      answerAtEndControl = this.quizControl.answers_at_end,
      answers_at_end = answerAtEndControl.value,
      durationControl = this.quizControl.duration;

    if (single_attempt) {
      answerAtEndControl.setValidators([Validators.required]);
      durationControl.setValidators([Validators.required, Validators.pattern(/^([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)$/g)]);
    } else{
      answerAtEndControl.clearValidators();
      durationControl.setValidators([Validators.pattern(/^([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)$/g)]);
    }
    answerAtEndControl.updateValueAndValidity();
    durationControl.updateValueAndValidity();
  }

  setToNull(input) {
    if(input.value === false) {
      input.setValue(null);
    }
  }

  // get sub category detail for breadcrumb
  subCategoryDetail:any = {};
  getSubcategoryDetail() {
    this.categoryService.getEachSubCategory(this.subCatId)
      .pipe(first())
      .subscribe(
        data => {
          this.subCategoryDetail = data;

          this.breadcrumb_data.push({name: data.category_name, link: `/dashboard/category/${this.catId}`});
          this.breadcrumb_data.push({name: data.sub_category});
          this.breadcrumbService.store(this.breadcrumb_data);
        },
        error => {

        }
      );
  }

  /* list of quizzes */
  quizes = [];
  getQuizList() {
    let params = {
      page: this.currentPage,
      category: this.catId,
      sub_category: this.subCatId,
    };
    this.quizService.getQuizes(params)
      .pipe(first())
      .subscribe(
        data => {
          this.quizes = data.results;
          this.totalQuizzes = data.count;
        },
        error => {
          const errorMessage = error.detail ?
            error.detail :
            'Unable to process the operation. Please contact the administrator.';
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }
  /* add category */
  addQuiz() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addQuizForm.invalid) {
      return;
    }
    this.loading = true;

    let quizData= {
      title: this.quizControl.title.value,
      description: this.quizControl.description.value,
      category: this.catId,
      sub_category: this.subCatId,
      max_questions: this.quizControl.max_questions.value,
      pass_mark: this.quizControl.pass_mark.value,
      success_text: this.quizControl.success_text.value,
      fail_text: this.quizControl.fail_text.value,
      points: this.quizControl.points.value,
      random_order: this.quizControl.random_order.value,
      single_attempt: this.quizControl.single_attempt.value,
      answers_at_end: this.quizControl.answers_at_end.value ? this.quizControl.answers_at_end.value : false,
      duration_time: this.quizControl.duration.value ? this.quizControl.duration.value : null,
      draft: this.quizControl.draft.value,
      url: 'quiz'
    };
    this.quizService.createQuiz(quizData)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          const successMessage = quizData.draft ?
            'Quiz added as draft.' :
            'Quiz created.';

          this.message = {
            status: 'success',
            msg: successMessage
          };
          location.reload();
        },
        error => {
          this.loading = false;
          const errorMessage = error.detail ?
            error.detail :
              (error.points ?
                'Points per question: '+error.points[0] :
                  'Unable to process the operation. Please contact the administrator.'
                );

          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  change(each) {
    event.preventDefault();
    this.form.label = each ? 'Edit' : 'Create';
    this.form.id = each ? each.id : null;

    const title = each ? each.title : '',
      description = each ? each.description : '',
      category = each ? each.category : '',
      sub_category = each ? each.sub_category : '',
      max_questions = each ? each.max_questions : '',
      pass_mark = each ? each.pass_mark : '',
      success_text = each ? each.success_text : '',
      fail_text = each ? each.fail_text : '',
      points = each ? each.points : '',
      random_order = each ? each.random_order : false,
      single_attempt = each ? each.single_attempt : false,
      answers_at_end = each ? (each.answers_at_end ? each.answers_at_end : null) : null, // for setting input value null so that validator works
      duration = each ? each.duration_time : null,
      draft = each ? each.draft : true;

    this.setValidation();

    this.quizControl.title.setValue(title);
    this.quizControl.description.setValue(description);
    this.quizControl.max_questions.setValue(max_questions);
    this.quizControl.pass_mark.setValue(pass_mark);
    this.quizControl.success_text.setValue(success_text);
    this.quizControl.fail_text.setValue(fail_text);
    this.quizControl.points.setValue(points);
    this.quizControl.random_order.setValue(random_order);
    this.quizControl.single_attempt.setValue(single_attempt);
    this.quizControl.answers_at_end.setValue(answers_at_end);
    this.quizControl.duration.setValue(duration);
    this.quizControl.draft.setValue(draft);

    this.message = {
      status: '',
      msg: ''
    };
  }

  /* update quiz */
  updateQuiz() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addQuizForm.invalid) {
      return;
    }
    this.loading = true;

    let quizData = {
      title: this.quizControl.title.value,
      description: this.quizControl.description.value,
      category: this.catId,
      sub_category: this.subCatId,
      max_questions: this.quizControl.max_questions.value,
      pass_mark: this.quizControl.pass_mark.value,
      success_text: this.quizControl.success_text.value,
      fail_text: this.quizControl.fail_text.value,
      points: this.quizControl.points.value,
      random_order: this.quizControl.random_order.value,
      single_attempt: this.quizControl.single_attempt.value,
      answers_at_end: this.quizControl.answers_at_end.value ? this.quizControl.answers_at_end.value : false,
      duration_time: this.quizControl.duration.value ? this.quizControl.duration.value : null,
      draft: this.quizControl.draft.value,
      url: 'quiz'
    };

    this.quizService.updateQuiz(this.form.id, quizData)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          const successMessage = 'Quiz updated.';
          this.message = {
            status: 'success',
            msg: successMessage
          };
          location.reload();
        },
        error => {
          this.loading = false;
          const errorMessage = error.detail ?
            error.detail :
              (error.points ?
                'Points per question: '+error.points[0] :
                  'Unable to process the operation. Please contact the administrator.'
                );
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }
  OnCsvSelect(event){
    if(event.target.files.length > 0){
      const file =event.target.files[0];
      this.csvControl.file.setValue(file);

      var fileName =file.name;
      $(event.target).next('.custom-file-label').html(fileName);
    }
  }
  get csvControl() {
    return this.csvForm.controls;
  }
  csvSubmit(){
    this.csvSubmitted = true;
    
    if(this.csvForm.invalid){
      return;
    }
    this.csvLoading =true;

    const formData =new FormData();
    formData.append('file',this.csvControl.file.value),
    formData.append('quizes',this.csvControl.quizes.value);

    this.userService.uploadCsv(formData)
    .pipe(first())
    .subscribe(
      data=>{
        this.notification.class ='success';
        this.notification.title ='Success!';
        this.notification.msg ='Csv upload successfully';
        this.alertService.setMessage(this.notification);
      },
      error=>{
        this.loading =false;
        this.notification.class = 'warning';
        this.notification.title = 'Sorry!';
        this.notification.msg = 'csv not uploaded';
        this.alertService.setMessage(this.notification);
      }
    )
  }

  /* to publish quiz, make draft false */
  publishQuiz(quiz) {
    let quizData = {
      title: quiz.title,
      description: quiz.description,
      category: quiz.category,
      sub_category: quiz.sub_category,
      max_questions: quiz.max_questions,
      pass_mark: quiz.pass_mark,
      success_text: quiz.success_text,
      fail_text: quiz.fail_text,
      points: quiz.points,
      random_order: quiz.random_order,
      single_attempt: quiz.single_attempt,
      answers_at_end: quiz.answers_at_end ? quiz.answers_at_end : false,
      duration_time: quiz.duration_time ? quiz.duration_time : null,
      draft: false,
      url: 'quiz'
    };

    this.quizService.updateQuiz(quiz.id, quizData)
      .pipe(first())
      .subscribe(
        data => {
          let successMessage = 'Quiz published successfully.';
          this.quizMessage = {
            status: 'success',
            msg: successMessage
          };
          location.reload();
        },
        error => {
          let errorMessage = error.detail ?
            error.detail :
            'Unable to process the operation. Please contact the administrator.';
          this.quizMessage = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  /* to unpublish quiz, make draft false */
  unPublish(quiz) {
    let quizData = {
      title: quiz.title,
      description: quiz.description,
      category: quiz.category,
      sub_category: quiz.sub_category,
      max_questions: quiz.max_questions,
      pass_mark: quiz.pass_mark,
      success_text: quiz.success_text,
      fail_text: quiz.fail_text,
      points: quiz.points,
      random_order: quiz.random_order,
      single_attempt: quiz.single_attempt,
      answers_at_end: quiz.answers_at_end ? quiz.answers_at_end : false,
      duration_time: quiz.duration_time ? quiz.duration_time : null,
      draft: true,
      url: 'quiz'
    };

    this.quizService.updateQuiz(quiz.id, quizData)
      .pipe(first())
      .subscribe(
        data => {
          let successMessage = 'Quiz unpublished successfully.';
          this.quizMessage = {
            status: 'success',
            msg: successMessage
          };
          location.reload();
        },
        error => {
          let errorMessage = error.detail ?
            error.detail :
            'Unable to process the operation. Please contact the administrator.';
          this.quizMessage = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }


  /* delete quiz */
  deleteQuiz(id) {
    event.preventDefault();
    let isConfirmed = confirm('Are you sure to delete this quiz?');
    if (!isConfirmed) {
      return false;
    }
    this.quizService.deleteQuiz(id)
      .pipe(first())
      .subscribe(
        data => {
          let  successMessage = 'Quiz deleted.';
          this.message = {
            status: 'success',
            msg: successMessage
          };
          location.reload();
        },
        error => {
        const errorMessage = error.detail ?
            error.detail :
            'Unable to process the operation. Please contact the administrator.';
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  resetModal() {
    this.form.label = 'Create';
    this.form.id = null;
    this.initForm();
    this.message = {
      status: '',
      msg: ''
    };
  }

  setQAPage(page: number) {
    this.QACurrentPage = page;
  }

  resetQAModal(quiz) {
    this.message = {
      status: '',
      msg: ''
    };

    setTimeout(() => {
      this.setQAPage(1);
      this.getQA(quiz);
    }, 50);
  }

  /* get question answers for each quiz */
  quizQAList = [];
  quizDetail = null;
  getQA(quizDetail) {
    this.quizDetail = quizDetail;
    let  params = {
      page: this.QACurrentPage,
      quiz: quizDetail.id
    };
    this.questionAnswerService.getQuestions(params)
      .pipe(first())
      .subscribe(
        data => {
          let questionData = data.results;
          this.totalQuestionAns = data.count;
          for (let i = questionData.length - 1; i >= 0; i--) {
            const each_question = questionData[i];
            each_question.answers = [];

            let answers = [];
            let getAnswerList = (pageNum) => {
              const  ansParam = {
                page: pageNum,
                question: each_question.id
              };

              this.questionAnswerService.getAnswers(ansParam)
                .pipe(first())
                .subscribe(
                  ansData => {
                    if(ansData.next) {
                      pageNum++;
                      getAnswerList(pageNum);
                    }

                    ansData.results.forEach(eachAns => {
                      answers.push(eachAns);
                    });

                    if(!ansData.next) {
                      each_question.answers = answers;
                    }

                  },
                  ansError => {
                    let  errorMessage = ansError.detail ?
                      ansError.detail :
                      'Unable to process the operation. Please contact the administrator.';
                    this.message = {
                      status: 'danger',
                      msg: errorMessage
                    };
                  });
            }
            getAnswerList(1);
          }
          this.quizQAList = questionData;
        },
        error => {
          const  errorMessage = error.detail ?
            error.detail :
            'Unable to process the operation. Please contact the administrator.';
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  /* delete question */
  deleteQuestionAnswer(id) {
    event.preventDefault();
    let isConfirmed = confirm('Are you sure to delete this question with its answers?');
    if (!isConfirmed) {
      return false;
    }
    this.questionAnswerService.deleteQuestion(id)
      .pipe(first())
      .subscribe(
        data => {
          let successMessage = 'The requested question with its answers are deleted.';
          this.message = {
            status: 'success',
            msg: successMessage
          };
        },
        error => {
          let  errorMessage = error.detail ?
            error.detail :
            'Unable to process the operation. Please contact the administrator.';
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  closeYourModal() {
    this.closeModal();
  }

   // call this wherever you want to close modal
  private closeModal(): void {
    document.getElementById('closeButton').click();
  }

  /* get quiz status */
  getQuizStatus(quiz) {
    let status = {
      label: '',
      class: ''
    };
    if(!quiz.draft) {
      status.label = 'Published';
      status.class = 'success';
      return status;
    }
    else if(quiz.has_questions) {
      status.label = 'Draft, has questions';
      status.class = 'primary';
      return status;
    }
    else {
      status.label = 'Draft, No question added';
      status.class = 'info';
      return status;
    }
  }

}
