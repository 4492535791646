import { Component, OnInit, Input, Output } from '@angular/core';
import { UserService, AlertService } from '../services';
import * as KhaltiCheckout from 'khalti-web';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { EventEmitter } from 'protractor';
import { User } from '../_models/user';

@Component({
  selector: 'app-khalti-integration',
  templateUrl: './khalti-integration.component.html',
  styleUrls: ['./khalti-integration.component.scss']
})
export class KhaltiIntegrationComponent implements OnInit {
  @Input() quiz_price:any;
  id:any;
  subCatId:any;
  khaltiCheckout: any;
  currentUser: User;
  @Input()
  set sCatIdd(sCatIdd:number){
    this.subCatId =sCatIdd;
  }
  message = {
    status: '',
    msg: ''
  };
  constructor(
    private userService:UserService,
    private alertService:AlertService,
    private route:ActivatedRoute
  ) {
   }
  ngOnInit() {
     this.Khalti();
     console.log(this.subCatId);
  }
  Khalti(){
    let khaltiVerification =(response) =>{
      let payload =new FormData();
     
      
      payload.append('amount',response.amount);
      payload.append('subcategory',this.subCatId);
      payload.append('token',response.token);
      this.userService.khaltiVerify(payload)
      .pipe(first())
      .subscribe(
        data=>{
          let successMessage = 'Payment Successfull';
          this.message = {
            status: 'success',
            msg: successMessage
          };
          this.alertService.success(successMessage);
          location.reload();

        },
        error =>{
          let errorMessage ='Unable to process the operation. Please contact the administrator.'
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
          this.alertService.error(errorMessage);

        });
    }

    let config = {
      // replace this key with yours
      "publicKey": "test_public_key_5bdea77b9efd475189e992040f96de00",
      "productIdentity": `OR-${this.sCatIdd}-1`,
      "productName": "AWS Certified Associate developer",
      "productUrl": `${location.origin}${location.pathname}`,
      "eventHandler": {
        onSuccess(response) {
          // hit merchant api for initiating verfication
          // console.log(response);
          khaltiVerification(response);
        },

        // onError handler is optional
        onError(error) {
          // handle errors
          console.log(error);
        },

        onClose() {
          console.log('widget is closing');
        }

      }
    };
    this.khaltiCheckout = new KhaltiCheckout(config);
  }
  payWithKhalti() {
    // khalti says - Amount to pay in paisa. Minimum transaction amount is 1000 paisa ie Rs 10.
    // since khalti divides the amount by 100 and we have to send actual amount multiply it by 100
    let amount = this.quiz_price *100;
    this.khaltiCheckout.show({
      amount: amount
    });      
  }
  
}
