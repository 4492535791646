import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms'; // if ngModel is used then FormsModule needs to be imported
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AlertComponent } from './alert/alert.component';
import { PaginationModule } from 'ngx-bootstrap';

import { ErrorInterceptor, JwtInterceptor } from './_helpers';
import { SignupVerifyComponent } from './signup-verify/signup-verify.component';
import { ChangeExistingComponent } from './password/change-existing/change-existing.component';
import { ResetComponent } from './password/reset/reset.component';
import { CategoryComponent } from './admin/category/category.component';
import { SubCategoryComponent } from './admin/sub-category/sub-category.component';
import { QuizComponent } from './admin/quiz/quiz.component';
import { QuestionComponent } from './admin/quiz/question/question.component';
import { AnswerComponent } from './admin/quiz/answer/answer.component';
import { QuizesComponent } from './user/quizes/quizes.component';
import { TakeQuizComponent } from './user/take-quiz/take-quiz.component';
import { CategoriesComponent } from './user/categories/categories.component';
import { SubCategoriesComponent } from './user/sub-categories/sub-categories.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RecordsComponent } from './records/records.component';
import { LandingpageComponent } from './landingpage/landingpage.component';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { KhaltiIntegrationComponent } from './khalti-integration/khalti-integration.component';

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LoginComponent,
    DashboardComponent,
    AlertComponent,
    SignupVerifyComponent,
    ChangeExistingComponent,
    ResetComponent,
    CategoryComponent,
    SubCategoryComponent,
    QuizComponent,
    QuestionComponent,
    AnswerComponent,
    QuizesComponent,
    TakeQuizComponent,
    CategoriesComponent,
    SubCategoriesComponent,
    BreadcrumbComponent,
    RecordsComponent,
    LandingpageComponent,
    KhaltiIntegrationComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),
    TooltipModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
