import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { AlertService, BreadcrumbService, CategoryService, QuizService, QuestionAnswerService } from '../../../services';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {

  addQuestionForm: FormGroup;
  loading = false;
  public submitted = false;
  form = {
    label: 'Add',
    id: null
  };
  message = {
    status: '',
    msg: ''
  };
  quizId = null;
  questionId = null;
  breadcrumb_data:any;

  /* editor options */
  public options: Object = {
    placeholderText: 'Edit Here!',
    toolbarButtons: ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough',
      'subscript', 'superscript', '|', 'fontFamily', 'fontSize', 'color', 'inlineClass',
      'inlineStyle', 'paragraphStyle', 'lineHeight', '|', 'paragraphFormat', 'align',
      'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '-', 'insertLink',
      'insertTable', '|', 'emoticons', 'fontAwesome',
      'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting', '|',
      'spellChecker', 'help', 'html', '|', 'undo', 'redo'
    ]
  };

  // urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&%'\(\)\*\+,;=.]+$/;

  constructor(
    private alertService: AlertService,
    private breadcrumbService: BreadcrumbService,
    private formBuilder: FormBuilder,
    private categoryService: CategoryService,
    private quizService: QuizService,
    private questionAnswerService: QuestionAnswerService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.breadcrumb_data = [{name: 'Dashboard', link: '/dashboard'}];
  }

  ngOnInit() {
    this.quizId = this.route.snapshot.paramMap.get('quizId');
    this.questionId = this.route.snapshot.paramMap.get('questionId');
    this.getQuizDetail(this.quizId);

    if(this.questionId) {
      this.getQuesAnsDetail(this.questionId);
    }

    /* initialize form */
    if(!this.questionId) {
      this.addQuestionForm = this.formBuilder.group({
        content: ['', Validators.required],
        explanation: [''],
        question_url: ['', [
          // Validators.pattern(this.urlRegex),
          // Validators.pattern(/^\S+$/)
        ]],
        answer_order: [''],
        answers: this.formBuilder.array([
          this.initAnswer('', false),
        ]),
        // name: ['', [Validators.required, Validators.minLength(5)]],
      });
    } else {
      this.addQuestionForm = this.formBuilder.group({
        content: ['', Validators.required],
        explanation: [''],
        question_url: ['', [
          // Validators.pattern(this.urlRegex),
          // Validators.pattern(/^\S+$/)
        ]],
        answer_order: [''],
        answers: this.formBuilder.array([])
      })
    }
  }

  getUrlValidation(url) {
    if(url) {
      url = url.split(',');
      if(url instanceof Array) {
        for (var i = url.length - 1; i >= 0; i--) {
          if(!this.urlRegex.test(url[i])){
            return false;
          } else {
            return true;
          }
        }
      }
    }

    return true;

  }

  initAnswer(content, correct) {
    // initialize our answer
    return this.formBuilder.group({
      ans_content: [content, Validators.required],
      correct: [correct]
    });
  }

  addAnswer() {
    event.preventDefault();
    // add answer to the list
    const control = <FormArray>this.addQuestionForm.controls['answers'];
    control.push(this.initAnswer('', false));
  }

  removeAnswer(i: number) {
    event.preventDefault();
    // remove answer from the list
    const control = <FormArray>this.addQuestionForm.controls['answers'];
    control.removeAt(i);
  }

  /* get the error or any value */
  get questionControl() {
    return this.addQuestionForm.controls;
  }

  quizDetail:any = {};
  getQuizDetail(id) {
    this.quizService.getEachQuiz(id)
      .pipe(first())
      .subscribe(
        data => {
          this.quizDetail = data;

          /* update breadcrumb data */
          this.breadcrumb_data.push({name: data.category_name, link: `/dashboard/category/${this.quizDetail.category}`});
          this.breadcrumb_data.push({name: data.sub_category_name, link: `/dashboard/category/${this.quizDetail.category}/sub_category/${this.quizDetail.sub_category}/quiz`});
          this.breadcrumb_data.push({name: `${data.title} - ${this.questionId ? 'Edit' : 'Add'} Question`});
          this.breadcrumbService.store(this.breadcrumb_data);

          this.getCategoryDetail(data);
        },
        error => {
          let errorMessage = error.detail ?
            error.detail :
            'Unable to process the operation. Please contact the administrator.';
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  getCategoryDetail(quiz) {
    this.categoryService.getEachCategory(quiz.category)
      .pipe(first())
      .subscribe(
        data => {
          this.getSubCategoryList(data.id);
        },
        error => {
          let errorMessage = error.detail ?
            error.detail :
            'Unable to process the operation. Please contact the administrator.';
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  subCategories = [];
  getSubCategoryList(category) {
    let params = {
      category: category
    };
    this.categoryService.getSubCategories(params)
      .pipe(first())
      .subscribe(
        data => {
          this.subCategories = data;
        },
        error => {
          let errorMessage = error.detail ?
            error.detail :
            'Unable to process the operation. Please contact the administrator.';
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  // questions = [];
  // getQuestionList() {
  //   let params = {};
  //   this.questionAnswerService.getQuestions(params)
  //     .pipe(first())
  //     .subscribe(
  //       data => {
  //         this.questions = data;
  //       },
  //       error => {
  //         let errorMessage = error.detail ?
  //           error.detail :
  //           'Unable to process the operation. Please contact the administrator.';
  //         this.message = {
  //           status: 'danger',
  //           msg: errorMessage
  //         };
  //       });
  // }

  /* add question */
  addQuestion() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addQuestionForm.invalid) {
      return;
    }

    let checkUrlValidity = this.getUrlValidation(this.questionControl.question_url.value);
    if(!checkUrlValidity) {
      return;
    }
    this.loading = true;

    let questionData = {
      id: this.form.id,
      quiz: [this.quizId],
      category: this.quizDetail.category,
      sub_category: this.quizDetail.sub_category,
      content: this.questionControl.content.value,
      explanation: this.questionControl.explanation.value,
      question_url: this.questionControl.question_url.value,
      answer_order: this.questionControl.answer_order.value
    };

    let answers = this.questionControl.answers.value;

    this.questionAnswerService.createQuestion(questionData)
      .pipe(first())
      .subscribe(
        data => {
          for (var i = answers.length - 1; i >= 0; i--) {
            let answerData = {
              question: data.id,
              content: answers[i].ans_content,
              correct: answers[i].correct
            };
            this.createAnswer('add', answerData);
          }

        },
        error => {
          this.loading = false;
          let errorMessage = error.detail ?
            error.detail :
            'Unable to process the operation. Please contact the administrator.';
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  getQuesAnsDetail(id) {
    this.questionAnswerService.getEachQuestion(id)
      .pipe(first())
      .subscribe(
        data => {
          let question = data;
          this.getAnswersForaQuestion(question);
        },
        error => {
          let errorMessage = error.detail ?
            error.detail :
            'Unable to process the operation. Please contact the administrator.';
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }


  getAnswersForaQuestion(question) {
    let answerLists = [];
    let getAnswers = (pageNum: number) => {
      let ansParams = {
        question: question.id,
        page: pageNum
      };
      this.questionAnswerService.getAnswers(ansParams)
        .pipe(first())
        .subscribe(
          ansData => {
            if(ansData.next) {
              pageNum++;
              getAnswers(pageNum);
            }
            let subcatList = ansData.results;
  
            subcatList.forEach(result => {
              answerLists.push(result);
            });
  
            if(!ansData.next){
              question.answers = answerLists;
              this.change(question);
            }

          },
          ansError => {
            let errorMessage = ansError.detail ?
              ansError.detail :
              'Unable to process the operation. Please contact the administrator.';
            this.message = {
              status: 'danger',
              msg: errorMessage
            };
          });
    }

    getAnswers(1);
  }

  change(each) {
    event.preventDefault();
    this.form.label = each ? 'Edit' : 'Add';
    this.form.id = each ? each.id : null;
    let content = each ? each.content : '',
      explanation = each ? each.explanation : '',
      question_url = each ? each.question_url : '',
      answer_order = each ? each.answer_order : '';

    this.questionControl.content.setValue(content);
    this.questionControl.explanation.setValue(explanation);
    this.questionControl.question_url.setValue(question_url);
    this.questionControl.answer_order.setValue(answer_order);


    let control = <FormArray>this.addQuestionForm.controls['answers'];
    for (var i = each.answers.length - 1; i >= 0; i--) {
      let ans_content = each.answers[i].content,
        correct = each.answers[i].correct;
      control.push(this.initAnswer(ans_content, correct));
    }

    this.message = {
      status: '',
      msg: ''
    };
  }

  /* update question */
  updateQuestion() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addQuestionForm.invalid) {
      return;
    }
    let checkUrlValidity = this.getUrlValidation(this.questionControl.question_url.value);
    if(!checkUrlValidity) {
      return;
    }
    this.loading = true;

    let questionData = {
      id: this.form.id,
      quiz: [this.quizId],
      category: this.quizDetail.category,
      sub_category: this.quizDetail.sub_category,
      content: this.questionControl.content.value,
      explanation: this.questionControl.explanation.value,
      question_url: this.questionControl.question_url.value,
      answer_order: this.questionControl.answer_order.value
    };

    this.questionAnswerService.updateQuestion(this.form.id, questionData)
      .pipe(first())
      .subscribe(
        data => {

          let answers = this.questionControl.answers.value;
          this.deleteAnswer(data.id, answers);
        },
        error => {
          this.loading = false;
          let errorMessage = error.detail ?
            error.detail :
            'Unable to process the operation. Please contact the administrator.';
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }

  /* remove answer */
  deleteAnswer(questionId, answersTobeCreated) {
    let allOldAnswers = [];
    let getOldAnswers = (pageNumber: number) => {
      let questionParam = {
        question: questionId,
        page: pageNumber
      };
      this.questionAnswerService.getAnswers(questionParam)
        .pipe(first())
        .subscribe(
          answers => {
            if(answers.next) {
              pageNumber++;
              getOldAnswers(pageNumber);
            }
            let subcatList = answers.results;
  
            subcatList.forEach(result => {
              allOldAnswers.push(result);
            });
  
            if(!answers.next){            
              /* delete old answers */
              if(allOldAnswers.length > 0) {
                for (var i = allOldAnswers.length - 1; i >= 0; i--) {
                  this.questionAnswerService.deleteAnswer(allOldAnswers[i].id)
                    .pipe(first())
                    .subscribe(
                      data => {
                        // answer deleted
                      },
                      error => {
                        let errorMessage = error.detail ?
                          error.detail :
                          'Unable to process the operation. Please contact the administrator.';
                        this.message = {
                          status: 'danger',
                          msg: errorMessage
                        };
                      });
                }
              }

              /* create new answers */
              for (var i = answersTobeCreated.length - 1; i >= 0; i--) {
                let answerData = {
                  question: questionId,
                  content: answersTobeCreated[i].ans_content,
                  correct: answersTobeCreated[i].correct
                };

                this.createAnswer('edit', answerData);
              }
            }
          },
          answersErr => {
            let errorMessage = answersErr.detail ?
              answersErr.detail :
              'Unable to process the operation. Please contact the administrator.';
            this.message = {
              status: 'danger',
              msg: errorMessage
            };
          });
    }

    getOldAnswers(1);

  }

  /* create answer */
  createAnswer(type, answerData) {
    this.questionAnswerService.createAnswer(answerData)
      .pipe(first())
      .subscribe(
        ansData => {
          this.loading = false;
          let update = type === 'add' ? 'added' : 'updated',
            successMessage = `Question answer ${update}.`;
          this.alertService.success(successMessage, true);
          this.router.navigate([`/dashboard/category/${this.quizDetail.category}/sub_category/${this.quizDetail.sub_category}/quiz`]);
        },
        ansError => {
          this.loading = false;
          let errorMessage = ansError.detail ?
            ansError.detail :
            'Unable to process the operation. Please contact the administrator.';
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        }
      );
  }
}
