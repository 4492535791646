import { Injectable } from '@angular/core';
import { HttpClient,  HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
apiUrl = environment.apiUrl;
  constructor(
    private http: HttpClient
  ) {
  }

  signup(data) {
    return this.http.post<any>(`${this.apiUrl}/api/accounts/signup/`, data)
      .pipe(map(user => {
        return user;
      }));
  }

  // signup verification
  verifySignup(params) {
    const urlParams = {
      params: params
    };

    return this.http.get<any>(`${this.apiUrl}/api/accounts/signup/verify/`, urlParams)
      .pipe(map(user => {
        return user;
      }));
  }

  // Call this endpoint to send an email to a user with a code so that they can request their password.
  requestToResetPassword(data) {
    return this.http.post<any>(`${this.apiUrl}/api/accounts/password/reset/`, data)
      .pipe(map(user => {
        return user;
      }));
  }

  // Call this endpoint to verify the password reset code when user click the link in the password reset email
  verifyUserEmail(params) {
    const urlParams = {
      params: params
    };

    return this.http.get<any>(`${this.apiUrl}/api/accounts/password/reset/verify/`, urlParams)
      .pipe(map(user => {
        return user;
      }));
  }

  // Call this endpoint with the password reset code and the new password, to reset the user’s password
  resetPassword(data) {
    return this.http.post<any>(`${this.apiUrl}/api/accounts/password/reset/verified/`, data)
      .pipe(map(user => {
        return user;
      }));
  }

  // Call this endpoint to change a user’s existing password
  changeExistingPassword(data) {
    return this.http.post<any>(`${this.apiUrl}/api/accounts/password/change/`, data)
      .pipe(map(user => {
        return user;
      }));
  }

  getUser() {
    return this.http.get<any>(`${this.apiUrl}/api/accounts/user-me/`)
      .pipe(map(user => {
        return user;
      }));
  }

  getAllUsers(params) {
    const urlParams = {
      params: params
    };
    return this.http.get<any>(`${this.apiUrl}/api/accounts/users/`, urlParams)
      .pipe(map(response => {
        return response;
      }));
  }
  inviteUser(data) {
    return this.http.post<any>(`${this.apiUrl}/inviteuser/`, data)
      .pipe(map(user => {
        return user;
      }));
  }
  updateUser(id, data) {
    return this.http.put<any>(`${this.apiUrl}/api/accounts/users/${id}/`, data)
      .pipe(map(response => {
        return response;
      }));
  }
  deleteUser(id) {
    return this.http.delete<any>(`${this.apiUrl}/api/accounts/users/${id}/`)
      .pipe(map(response => {
        return response;
      }));
  }
  uploadCsv(data){
    let headers = new HttpHeaders();
    return this.http.post<any>(`${this.apiUrl}/csvupload/`, {headers:headers})
    .pipe(map(user => {
      return user;
    }));
  }
   // Call this endpoint to verify khalti payment integration
  khaltiVerify(data){
  return this.http.post<any>(`${this.apiUrl}/khaltiverify/`, data)
  .pipe(map(user =>{
    return user;
  }));
  }

  
}
