import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { AlertService, AuthenticateService,BreadcrumbService, CategoryService, QuizService, UserService } from '../../services';
import { User } from '../../_models/user';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-quizes',
  templateUrl: './quizes.component.html',
  styleUrls: ['./quizes.component.scss'],
})
export class QuizesComponent implements OnInit {
  [x: string]: any;
  message = {
    status: '',
    msg: ''
  };
  currentUser: User;
  catId = null;
  subCatId = null;
  breadcrumb_data: any;
  RecordsService: any;
  recordsDetails: any;
  recordsDetail: any;
  test:any;
  recordsService: any;
  totalFreeQuizzes: number = 0;
  totalRealQuizzes: number = 0;
  freeQuizList = [];
  quizId:null;
  quizArray:any;
  pageSize: number = 10; /* number of items per page */
  freeCurrentPage = 1; /* here the freeCurrentPage of pagination is set as 1. */
  commonId = [];

  /* on page changed, change the list of array per page */
  freePageChanged(event: any): void {
    this.freeCurrentPage = event.page;
    this.getFreeQuizzes();
  }
  currentPage = 1; /* here the currentPage of pagination is set as 1. */
  returnRealquiz = []; // it is the paginated list of the real quiz
  paidData = false;

  /* on page changed, change the list of array per page */
  pageChanged(event: any): void {
    this.currentPage = event.page; // change the page number as the page button is clicked
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.returnRealquiz = this.realQuizzesAvailable.slice(startItem, endItem);
  }

  constructor(
    private alertService: AlertService,
    private authenticateService: AuthenticateService,
    private breadcrumbService: BreadcrumbService,
    private categoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private quizService: QuizService,
    private userService:UserService
  ) {
    this.authenticateService.currentUser.subscribe(x => this.currentUser = x);

    this.breadcrumb_data = [{name: 'Dashboard', link: '/dashboard'}];
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        if (params.category && params.sub_category) {
          this.catId = params.category;
          this.subCatId = params.sub_category;
        } else {
          let message = 'Invalid link.'
          this.alertService.error(message, true);
          this.router.navigate([`/sub-categories?category=${this.catId}`]);
        }
      });

    this.getSubCategoryDetail(this.subCatId);
    this.getFreeQuizzes();
    this.getRealQuizzesAvailable();
    this.User();

  }

  subCategoryDetail:any = {};
  getSubCategoryDetail(id) {
    this.categoryService.getEachSubCategory(id)
      .pipe(first())
      .subscribe(
        data => {
          this.subCategoryDetail = data;

          /* update breadcrumb */
          this.breadcrumb_data.push({name: data.category_name, link: `dashboard/sub-categories?category=${this.catId}`});
          this.breadcrumb_data.push({name: data.sub_category, link: ''});
          this.breadcrumbService.store(this.breadcrumb_data);
        },
        error => {
          let errorMessage = error.detail ?
            error.detail :
            (error.category ?
              error.category[0] :
              'Unable to process the operation. Please contact the administrator.'
            );
          this.message = {
            status: 'danger',
            msg: errorMessage
          };
        });
  }
  
  getFreeQuizzes() {
    let params = {
      page: this.freeCurrentPage,
      single_attempt: false,
      category: this.catId,
      sub_category: this.subCatId,
      paid:false
    };
    this.quizService.getQuizes(params)
      .pipe(first())
      .subscribe(
        data => {
          this.totalFreeQuizzes = data.count;
          this.freeQuizList = data.results;
        },
        _error => {

        }
      );
  }
  User(){
    this.userService.getUser()
    .pipe(first())
    .subscribe(
      
      data=>{
        // console.log(data);
        // console.log(data);
        let a = data;
        // console.log(a);
       var quizArray = JSON.parse(JSON.stringify(a));
      //  console.log(quizArray.quizes);
              //  this.subject.next();

        var QuizData = quizArray.subcategory;
        //  console.log("Test"+QuizData);  
         this.test=QuizData;
        //  console.log(this.test);
       
      },
      _error=>{

      },
    )
    // return this.subject;
  }
  
  realQuizzesAvailable = [];
  getRealQuizzesAvailable() {
    let params = {
      user: this.currentUser.id,
      category: this.catId,
      sub_category: this.subCatId,
      paid:true,
      // quiz: [this.quizId],
    };
    this.quizService.getQuizes(params)
      .pipe(first())
      .subscribe(
        data => {
          this.realQuizzesAvailable = data;
          let subc =this.subCatId;
          //this.returnRealquiz = data.results;
          // this.commonId = [];
          this.User();

          
          let global = this.test;
          let requiredData = ''
          
          for(var each_data in this.test){
            
            if(this.test[each_data] == this.subCatId){
              requiredData = this.subCatId
              this.paidData=true
            }
          }
          console.log(requiredData);
          
          let value = data.results;
          this.returnRealquiz = value;
          this.totalRealQuizzes = value.length;
          console.log(this.returnRealquiz)
        });

          
          }
}
