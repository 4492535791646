import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { AlertService, BreadcrumbService, CategoryService } from '../../services';

@Component({
  selector: 'app-sub-categories',
  templateUrl: './sub-categories.component.html',
  styleUrls: ['./sub-categories.component.scss']
})
export class SubCategoriesComponent implements OnInit {

  catId = null;
  breadcrumb_data:any;

  pageSize: number = 10; /* number of items per page */
  currentPage = 1; /* here the currentPage of pagination is set as 1. */
  totalSubCats: number = 0;

  /* on page changed, change the list of array per page */
  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getSubCategoryList();
  }

  constructor(
    private alertService: AlertService,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private router: Router,
    private categoryService: CategoryService
  ) {
    this.breadcrumb_data = [{name: 'Dashboard', link: '/dashboard'}];
  }
  

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        if (params.category) {
          this.catId = params.category
        } else {
          let message = 'Invalid link.'
          this.alertService.error(message, true);
          this.router.navigate(['/dashboard']);
        }
      });

     this.getCategoryDetail(this.catId);
    this.getSubCategoryList();

  }

  categoryDetail:any = {};
  getCategoryDetail(id) {
    this.categoryService.getEachCategory(id)
      .pipe(first())
      .subscribe(
        data => {
          this.categoryDetail = data;

          /* update breadcrumb */
          this.breadcrumb_data.push({name: data.category, link: ''});
          this.breadcrumbService.store(this.breadcrumb_data);
        },
        error => {
          let errorMessage = error.detail ?
            error.detail :
            (error.category ?
              error.category[0] :
              'Unable to process the operation. Please contact the administrator.'
            );
          this.alertService.error(errorMessage, true);
          this.router.navigate(['/dashboard']);
        });
  }
  

  subCategories = [];
  getSubCategoryList() {
    let params = {
      category: this.catId,
      page: this.currentPage
    };
    this.categoryService.getSubCategories(params)
      .pipe(first())
      .subscribe(
        data => {
          this.subCategories = data.results;
          this.totalSubCats = data.count;
        },
        error => {
        });
  }

}
