import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; // if ngModule is used then FormModule is required
import { first } from 'rxjs/operators';

import { AlertService, AuthenticateService, UserService } from '../services';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signupForm: FormGroup;
  submitted = false;
  loading = false;
  notification = this.alertService.getMessageFormat();


  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private alertService: AlertService,
    private authenticateService: AuthenticateService,
    private router: Router,
    private route: ActivatedRoute,

  ) {
    // redirect to dashboard if already logged in
    if (this.authenticateService.currentUserValue) {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      // code :['']
    });
    // this.route.queryParams
    // .subscribe(params => {

    //   if (params.code) {
    //     this.f.code.setValue(params.code);
    //   }
    //   else {
    //     this.alertService.error('This is not a valid link.', true);
    //     this.router.navigate(['/']);
    //   }
    // });
  }

  // convenience getter for easy access to form fields
  get f() { return this.signupForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.signupForm.invalid) {
      return;
    }
    this.loading = true;

    let userData = {
      first_name: this.f.firstName.value,
      last_name: this.f.lastName.value,
      email: this.f.email.value,
      password: this.f.password.value,
      // code: this.f.code.value
    };
    this.userService.signup(userData)
      .pipe(first())
      .subscribe(
        data => {
          let message = 'Thank you for registering. Please login.'
          this.alertService.success(message, true);
          this.router.navigate(['/login']);
        },
        error => {
          this.loading = false;
          let message = error.detail ?
          error.detail :
          'Unable to process the operation. Please contact the administrator.';
          this.alertService.error(message);
        });
  }

}
